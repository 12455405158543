
import './App.css';
import Form from './components/Form/Form'
import Header from './components/Header/Header'


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Header/>
      </header>
      <Form />
    </div>
  );
}

export default App;
