

import {
    Button,
    Grid, TextField,IconButton, Autocomplete
  } from '@mui/material';
import styled from 'styled-components';

export const CustomButton = styled(Button)`
  border-radius: 1rem;
  color: #FFF;
  border: 1px solid #bababa;
  width: 7rem;
  height: 3.5rem;
  margin-left: 1.5rem !important;
  background-color: #F0F0F0;;

  @media(max-width: 991px) {
    width: 8rem;
    height: 3rem;
    font-size: 2rem;
    margin-top: 1rem !important;
    margin-left: 1.5rem !important;
  }
`;

export const CustomGrid = styled(Grid)`
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  width: 40%
`;

export const CustomTextField = styled(TextField)`
@media(max-width: 991px) {
  margin-top: 1rem !important;
}
`;
export const CopyField = styled(Grid)`
@media(max-width: 991px) {
  margin-top: 1rem !important;
}
`;
export const GridField = styled(Grid)`
@media(max-width: 991px) {
  margin-left: 2rem !important;
  width: width: 40%;
}
`;
export const CustomIconButton = styled(IconButton)`
@media(max-width: 991px) {
  // margin-left: 3rem !important;
}
`;
export const CopyTextField = styled(TextField)`
@media(max-width: 991px) {
  margin-left: 5rem !important;
}
`;
export const CustomGridField = styled(Grid)`
@media(max-width: 991px) {
  // 8
  display: block !important;
  margin-bottom: 2rem !important;
}
`;

export const CustomP = styled.p`
padding-top: 2rem !important;
@media(max-width: 991px) {
  margin-left: 5rem !important;
}
`;

export const OptionLabel = styled('div')`
  justifyContent: space-between;
  alignItems: center;
`;

export const OptionAutocomplete = styled(Autocomplete)`
  alignItems: center;
  width: 33rem;
  margin-left: 28rem !important;
  margin-top: 2rem !important;
  @media(max-width: 991px) {
    margin-left: 7rem !important;
    width: 10rem;
  }
`;
