import React, { useState, useLayoutEffect, useEffect } from 'react';
import toast from 'react-hot-toast';
import { TextField, IconButton, CircularProgress, Grid  } from '@mui/material';
import { CustomButton, CustomGrid, CopyTextField,CopyField,CustomP,OptionAutocomplete,
  CustomTextField, GridField, CustomGridField, CustomIconButton, OptionLabel
 } from './Form.style'
import { useMutation, gql, useLazyQuery } from "@apollo/client";
import { FileCopy } from '@mui/icons-material';


function Form() {
  const printWindowsHTML = `<html><head><title>Print</title>
  <style>
    html,body {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  </style>
  <style type="text/css" media="print">
  @page {
      size: auto;
      margin: 0;
  }
  </style>
  </head><body>`;

  const BARCODE_MUTATION = gql`
   mutation createBarcode($brandName: String!, $packSize: String!){
      createBarcode(brandName:$brandName, packSize:$packSize){
        message
        barcodeData{
          id
          brandName
          searchablePackSize
          barcodeNumber
          barcodeImage
        }
      }
  }
`;

  const PRODUCT_QUERY = gql`
  query product($search: String!, $pageCount: Int, $pageNumber: Int){
    product(search:$search, pageCount: $pageCount, pageNumber: $pageNumber){
        id
        brandName
        searchablePackSize
      }
    }
  `;

  

  const [createBarcode] = useMutation(BARCODE_MUTATION);
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [productData, setProductData] = useState(null);
  const [searchProduct, { loading, error, data }] = useLazyQuery(PRODUCT_QUERY);

  
  const [formData, setFormData] = useState({
    brandName: '',
    packSize: '',
  });
  const [responseData, setResponseData] = useState(null);
  const [isloading, setIsLoading] = useState(false);
  // const handleInputChange = event => {
  //   const { name, value } = event.target;
  //   setFormData(prevState => ({
  //     ...prevState,
  //     [name]: value
  //   }));
  // };
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };



  const handleSubmit = event => {
    event.preventDefault();
    setIsLoading(true)
    setResponseData(null)
    toast.success('Successfully toasted!');
    console.log(formData);
    const { brandName, searchablePackSize:packSize } = productData;
    createBarcode({
      variables:{
        brandName,
        packSize
      }
    }).then(({ data }) => {
      const { message } = data?.createBarcode || {};
      setResponseData(data?.createBarcode);
      if (Object.keys(data?.createBarcode || {}).length === 0) {
        toast.error("Product not found");
      } else {
        toast.success(message);
      }
      // console.log(message)
    })
    .catch((err) => {
      toast.error(err?.message);
    }).finally(() => {
      setIsLoading(false);
    })
    setFormData({
      brandName: '',
      packSize: '',
    })
    console.log(responseData)
    // Submit form data to server or perform other actions
  };

  const [text, setText] = useState('');
  const [copied, setCopied] = useState(false);
  const handleCopyClick = () => {
    navigator.clipboard.writeText(responseData?.barcodeData?.barcodeNumber ?? '')
      .catch(err => console.error('Failed to copy to clipboard:', err));
    setCopied(true); 
  };

  const [numCopies, setNumCopies] = useState(1);
  const [imageSize, setImageSize] = useState(120);

  const handleNumCopiesChange = (event) => {
    setNumCopies(event.target.value);
  };

  const handleImageSizeChange = (event) => {
    setImageSize(event.target.value);
  };

  // const handlePrintClick = () => {
  //   const printWindow = window.open('', 'Print');
  //   const image = document.createElement('img');
  //   image.src = `data:image/jpeg;base64,${responseData?.barcodeData?.barcodeImage ?? ''}`
  //   image.style.width = `${imageSize}px`;
  //   printWindow.document.body.appendChild(image);

  //   for (let i = 1; i < numCopies; i++) {
  //     const imageCopy = document.createElement('img');
  //     imageCopy.src = `data:image/jpeg;base64,${responseData?.barcodeData?.barcodeImage ?? ''}`
  //     imageCopy.style.width = `${imageSize}px`;
  //     printWindow.document.body.appendChild(imageCopy);
  //   }

  //   printWindow.print();
  //   printWindow.close();
  // };

  const handlePrintClick = () => {
    const printWindow = window.open('', 'Print');
    printWindow.document.write(printWindowsHTML);
    const image = document.createElement('img');
    image.src = `data:image/jpeg;base64,${responseData?.barcodeData?.barcodeImage ?? ''}`;
    image.style.width = `${imageSize}px`;
    image.style.paddingBottom = '10px'; // add padding-bottom to the image
    image.style.paddingLeft = '10px'; // add padding-left to the image
    printWindow.document.body.appendChild(image);
  
    for (let i = 1; i < numCopies; i++) {
      const imageCopy = document.createElement('img');
      imageCopy.src = `data:image/jpeg;base64,${responseData?.barcodeData?.barcodeImage ?? ''}`;
      imageCopy.style.width = `${imageSize}px`;
      imageCopy.style.paddingBottom = '10px'; // add padding-bottom to the image copy
      imageCopy.style.paddingLeft = '10px'; // add padding-left to the image copy
      printWindow.document.body.appendChild(imageCopy);
    }
  
    printWindow.print();
    printWindow.close();
  };


  const handleSearchProduct = (search) => {
    if (search.length >= 3) {
      searchProduct({ variables: { search, pageCount, pageNumber } });
    }
  };
  console.log(productData)
  // if (loading) return <CircularProgress 
  //   disableShrink
  //   size={22}
  //   thickness={5}
  //   style={{ marginLeft: '40rem', marginTop: '1rem' }}
  // />;

  // const {
  //    loading, error, data,
  //   } = useQuery(PRODUCT_QUERY, {
  //     variables
  //   });
  //   if (loading) return <CircularProgress />;
  //   if (error) return <div>{error.message}</div>;
  //   const {
  //     product = {}
  //   } = data || {};


  return (
    <div>


    <OptionAutocomplete
      id="movie-autocomplete"
      options={data ? data.product : []}
      getOptionSelected={(option, value) => option.brandName === value.brandName}
      getOptionLabel={(option) => option.brandName}
      onInputChange={(event, value) => handleSearchProduct(value)}
      onChange={(event, value) => setProductData(value)}
      renderOption={(props, option) => (
        <OptionLabel {...props}>
          <div style={{ display: 'grid' }}>
            <span style={{}}>Product Name: {option.brandName}</span>
            <span>Pack Size: {option.searchablePackSize}</span>
          </div>
        </OptionLabel>
      )}
      renderInput={(params) => <TextField {...params} label="Search Product" />}
      />
      {loading ? (<CircularProgress 
        disableShrink
        size={60}
        thickness={5}
        style={{ marginLeft: '40rem', marginTop: '4rem' }}
        />) : ""}
      {productData && (
        <CustomGrid>
          <form onSubmit={handleSubmit}>
            <CustomTextField
            label="Brand Name"
            name="brandName"
            value={productData.brandName}
            onChange={handleInputChange}
            required
            sx={{ marginLeft: '1rem !important' }}
          />
          <CustomTextField
            label="Pack Size"
            name="packSize"
            value={productData.searchablePackSize}
            onChange={handleInputChange}
            required
            sx={{ marginLeft: '1rem !important' }}
          />
          <CustomButton type="submit" variant="contained" disabled={isloading}>
            Submit
          </CustomButton>
          </form>
        </CustomGrid>
      )}

      {isloading ? (
        <CircularProgress
          disableShrink
          size={22}
          thickness={5}
          style={{ marginLeft: '40rem', marginTop: '1rem' }}
        />
      ) : ''}
      {responseData && (
        <>
        <CustomGridField  style={{ display: 'flex', alignItems: 'center' }}>
          <GridField style={{ marginLeft: '26rem' }}>
            <p style={{ marginLeft: '2.5rem' }}>Message: {responseData?.message ?? ''}</p>
            <img
              src={`data:image/jpeg;base64,${responseData?.barcodeData?.barcodeImage ?? ''}`}
              alt="img" width={300} />
          </GridField>
          <CopyTextField
            label="Copy Field"
            defaultValue={responseData?.barcodeData?.barcodeNumber ?? ''}
            onChange={(event) => setText(event.target.value)}
            sx={{ mr: 1, marginLeft: '3rem', marginTop: '2rem' }} />
          <CustomIconButton onClick={handleCopyClick} style={{ marginTop: '2rem' }}>
            <FileCopy />
          </CustomIconButton>
          {copied && <CustomP>Barcode copied to clipboard!</CustomP>}
        </CustomGridField >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* <div style={{marginBottom: '1rem'}}>
              <label style={{marginRight: '0.5rem'}} htmlFor="numCopies">Number of copies:</label>
              <input type="number" id="numCopies" value={numCopies} onChange={handleNumCopiesChange} />
            </div> */}
            <div style={{marginBottom: '1rem'}}>
              <label style={{marginRight: '0.5rem'}} htmlFor="imageSize">Image size (mm):</label>
              <input type="number" id="imageSize" value={imageSize} onChange={handleImageSizeChange} />
            </div>
            <button 
            style={{
              padding: '0.5rem 1rem',
              borderRadius: '0.5rem', 
              backgroundColor: '#007bff',
              color: '#fff', 
              border: 'none',
              cursor: 'pointer'
            }}
            onClick={handlePrintClick}>Print</button>
          </div>
        </>
      )
       }
    </div>
  );
}

export default Form;
